import "./index.scss";
import { Key, useEffect, useRef, useState } from "react";
import axios from "axios";
import { RecoveListType, ProfileParams } from "../../helpers/dataTypes";
import { useParams } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";

import loadingJson from "../../assets/personal-center/loading.json"

var browser = {
  versions: (function () {
    var u = navigator.userAgent;

    //  var app = navigator.appVersion;

    return {
      trident: u.indexOf("Trident") > -1 /*IE内核*/,

      presto: u.indexOf("Presto") > -1 /*opera内核*/,

      webKit: u.indexOf("AppleWebKit") > -1 /*苹果、谷歌内核*/,

      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") === -1 /*火狐内核*/,

      mobile: !!u.match(/AppleWebKit.*Mobile.*/) /*是否为移动终端*/,

      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) /*ios终端*/,

      android:
        u.indexOf("Android") > -1 ||
        u.indexOf("Linux") > -1 /*android终端或者uc浏览器*/,

      iPhone: u.indexOf("iPhone") > -1 /*是否为iPhone或者QQHD浏览器*/,

      iPad: u.indexOf("iPad") > -1 /*是否iPad*/,

      webApp: u.indexOf("Safari") === -1 /*是否web应该程序，没有头部与底部*/,

      souyue: u.indexOf("souyue") > -1,

      superapp: u.indexOf("superapp") > -1,

      weixin: u.toLowerCase().indexOf("micromessenger") > -1,

      Safari: u.indexOf("Safari") > -1,
    };
  })(),
};

const PersonalCenter = (): JSX.Element => {
  const { userId } = useParams<any>();
  const [selectTabOrd, setSelectTabOrd] = useState<number>(0);
  const [videoBoxHeight, setVideoBoxHeight] = useState<number>(0);
  const [profileData, setProfileData] = useState<ProfileParams>({
    level: 0,
    relation: {
      followee_count: 0,
      follower_count: 0,
      friends_count: 0,
    },
    user: {
      age: 0,
      bio: "",
      city: "",
      country: "",
      gender: 0,
      interests: [{ id: "", label: "", category: "" }],
      planet: "",
      user_name: "",
      unique_name: "",
      birth_date: "",
    },
  });
  const [postsList, setPostsList] = useState<RecoveListType>();
  const [likesList, setLikesList] = useState<RecoveListType>();
  const [loading, setLoading] = useState(true);
  const [textToCopy, setTextToCopy] = useState("");
  const [showMore, setShowMore] = useState(true);
  const content = useRef<HTMLDivElement>(null);
  const postsBox = useRef<HTMLDivElement>(null);
  const likesBox = useRef<HTMLDivElement>(null);
  const domain = process.env.REACT_APP_BASE_URL;

  const handleCopy = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Copied to clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  const selectTab = (ord: number) => {
    content.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      setSelectTabOrd(ord);
      if (ord === 0) {
        setVideoBoxHeight(postsBox.current?.clientHeight || 100);
      } else {
        setVideoBoxHeight(likesBox.current?.clientHeight || 100);
      }
    }, 300);
  };

  const openApp = () => {
    console.log(
      "%c🀀 browser.versions.ios",
      "color: #1d5673; font-size: 20px;",
      browser.versions.ios
    );

    if (browser.versions.ios) {
      window.location.href = "realxyz://";

      window.setTimeout(function () {
        window.location.href =
          "https://apps.apple.com/cn/app/real-3d-lens-face-swap-camera/id1641227122";

        window.location.href =
          "https://apps.apple.com/cn/app/real-3d-lens-face-swap-camera/id1641227122";
      }, 2000);
    } else if (browser.versions.android) {
      window.location.href = `real://profile/${userId}`;

      setTimeout(function () {
        window.location.href =
          "https://play.google.com/store/apps/details?id=xyz.nbrlabs.riel";
        // window.location.href = "https://www.crushe.io/";
      }, 2000);
    }
  };

  useEffect(() => {
    // setVideoBoxHeight(postsBox.current?.clientHeight|| 100)
    getProfileInfo();
    getPostsList();
    getLikesList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAge = (strBirthday: string) => {
    var returnAge;
    // 根据生日计算年龄
    //以下五行是为了获取出生年月日，如果是从身份证上获取需要稍微改变一下
    var strBirthdayArr = strBirthday.split("-");
    var birthYear = Number(strBirthdayArr[0]);
    var birthMonth = Number(strBirthdayArr[1]);
    var birthDay = Number(strBirthdayArr[2]);

    const d = new Date();
    var nowYear = d.getFullYear();
    var nowMonth = d.getMonth() + 1;
    var nowDay = d.getDate();

    if (nowYear === birthYear) {
      returnAge = 0; //同年 则为0岁
    } else {
      var ageDiff = nowYear - birthYear; //年之差
      if (ageDiff > 0) {
        if (nowMonth === birthMonth) {
          var dayDiff = nowDay - birthDay; //日之差
          if (dayDiff < 0) {
            returnAge = ageDiff - 1;
          } else {
            returnAge = ageDiff;
          }
        } else {
          var monthDiff = nowMonth - birthMonth; //月之差
          if (monthDiff < 0) {
            returnAge = ageDiff - 1;
          } else {
            returnAge = ageDiff;
          }
        }
      } else {
        returnAge = -1; //返回-1 表示出生日期输入错误 晚于今天
      }
    }

    return returnAge; //返回周岁年龄
  };

  // const getAstro = (strBirthday: string) => {
  //   var strBirthdayArr = strBirthday.split("-");
  //   // var birthYear = Number(strBirthdayArr[0]);
  //   var month = Number(strBirthdayArr[1]);
  //   var day = Number(strBirthdayArr[2]);

  //   var arr = [20, 19, 21, 21, 21, 22, 23, 23, 23, 23, 22, 22];

  //   const ord = month * 2 - (day < arr[month - 1] ? 2 : 0);
  //   const name = [
  //     "Capricorn",
  //     "Aquarius",
  //     "Pisces",
  //     "Aries",
  //     "Taurus",
  //     "Gemini",
  //     "Cancer",
  //     "Leo",
  //     "Virgo",
  //     "Libra",
  //     "Scorpio",
  //     "Sagittarius",
  //     "Capricorn",
  //   ];

  //   return name[ord / 2];
  // };

  const getProfileInfo = async () => {
    try {
      axios.interceptors.request.use(
        (config: any) => {
          if (process.env.NODE_ENV === "production")
            config.headers["postman"] = 1;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      let res = await axios({
        method: "post",
        url: `${domain}/user/v2/profile-compose`,
        data: {
          data: {
            user_id: userId,
          },
        },
      });
      console.log("res.data", res.data.data);
      let data = res.data.data;
      setTextToCopy(data?.user?.unique_name);
      setProfileData(data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error: any) {
      console.log(111111111111, error.response);
      setTimeout(() => {
        setLoading(true);
      }, 1000);
    }
  };

  const getPostsList = async () => {
    try {
      axios.interceptors.request.use(
        (config: any) => {
          if (process.env.NODE_ENV === "production")
            config.headers["postman"] = 1;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      let res = await axios({
        method: "get",
        url: `${domain}/feed/v1/user/${userId}/posts?offset=0`,
      });
      let data = res.data.data;
      setPostsList(data);
    } catch (error: any) {
      console.log(error.response);
    }
  };

  const getLikesList = async () => {
    try {
      axios.interceptors.request.use(
        (config: any) => {
          if (process.env.NODE_ENV === "production")
            config.headers["postman"] = 1;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      let res = await axios({
        method: "get",
        url: `${domain}/feed/v1/user/${userId}/liked-posts?offset=0`,
      });
      let data = res.data.data;
      setLikesList(data);
    } catch (error: any) {
      console.log(error.response);
    }
  };
  // 1 男 2女 3其他 4保密
  const getGenderType = (gender: number) => {
    let imgName;
    switch (gender) {
      case 1:
        imgName = "Male";
        break;
      case 2:
        imgName = "Female";
        break;
      case 3:
        imgName = "Others";
        break;
    }
    return imgName;
  };

  const getNickName = (nickName: string) => {
    return nickName.slice(0, 1).toLocaleUpperCase();
  };

  const getProfileLevelImg = (level: number) => {
    if (level >= 0 && level < 4) return 0;
    if (level >= 4 && level < 10) return 4;
    if (level >= 10 && level < 20) return 10;
    if (level > 20) return 20;
    return level;
  }

  return (
    <>
      <div className="personal_content">
        {/* loading */}
        <div
          className="loading"
          style={{
            zIndex: loading ? "999999" : "-10000",
            opacity: loading ? 1 : 0,
          }}
        >
          <div className="img_box">
            <Player
              autoplay
              loop
              src={loadingJson}
              style={{ height: "100px", width: "100px" }}
            >
            </Player>
           </div>

          <h6>LOADING...</h6>
        </div>
        {/* fixed header */}
        <div className="joinBox">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div className="joinBox-avater">
              <span>{getNickName(profileData?.user?.user_name)}</span>
            </div>
            <div className="joinBox-title">
              Best Crypto Social Life in NUWA Metaverse
            </div>
          </div>
          <button className="joinBox-btn" onClick={openApp}>
            Join Us
          </button>
        </div>
        {/* content */}
        <div
          className="content"
          style={{
            overflowY: loading ? "hidden" : "scroll",
          }}
          ref={content}
        >
          <div className="top_block"></div>
          <div className="bottom_block">
            <div className="userInfo-header">
              <div className="userInfo-header_left">
                <div className="userInfo-header_avater">
                  <span>{getNickName(profileData?.user?.user_name)}</span>
                </div>
                <div className="userInfo-header_name">
                  <div className="name">{profileData?.user?.user_name}</div>
                  <div
                    className="tips"
                    style={{
                      display: profileData?.user?.unique_name ? "" : "none",
                    }}
                  >
                    <span>@{profileData?.user?.unique_name} </span>
                    <img
                      src={
                        require("../../assets/personal-center/copy.png").default
                      }
                      alt=""
                      onClick={handleCopy}
                    />
                  </div>
                </div>
              </div>
              <div className="userInfo-header_level">
                <div className="icon">
                  <img
                    src={
                      require(`../../assets/personal-center/LV${getProfileLevelImg(profileData?.level)}.png`)
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="level">LV.{profileData?.level}</div>
              </div>
            </div>

            <div style={{ position: "relative" }}>
              <div
                style={{
                  display: profileData?.user?.bio ? "inline-block" : "none"
                }}
                className={[
                  "userInfo-bio",
                  showMore ? "ellipsis" : "ellipsis-3",
                ].join(" ")}
              >
                <span>{profileData?.user?.bio}</span>
              </div>
              <span
                className="userInfo-more"
                onClick={() => setShowMore(false)}
                style={{
                  display:
                    profileData?.user?.bio && showMore ? "block" : "none",
                }}
              >
                More
              </span>
            </div>

            <div className="userInfo-person">
              <span
                className={[
                  "gender",
                  profileData?.user?.gender !== 4
                    ? getGenderType(profileData.user.gender)
                    : null,
                ].join(" ")}
              >
                {profileData?.user?.gender ? (
                  <div className="item">
                    {profileData?.user?.gender !== 4 ? (
                      <img
                        src={
                          require(`../../assets/personal-center/${getGenderType(
                            profileData.user?.gender
                          )}.png`).default
                        }
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    {profileData?.user?.birth_date
                      ? getAge(profileData?.user?.birth_date)
                      : ""}
                  </div>
                ) : (
                  ""
                )}
              </span>
              <span className="locaion">
                <div className="item">
                  <img
                    style={{
                      display:
                        profileData?.user?.country || profileData?.user?.city
                          ? "block"
                          : "none",
                    }}
                    src={
                      require("../../assets/personal-center/location.png")
                        .default
                    }
                    alt=""
                  />
                  <span
                    style={{
                      display: profileData?.user?.country ? "block" : "none",
                    }}
                  >{`${profileData?.user?.country} . `}</span>
                  <span
                    style={{
                      display: profileData?.user?.city ? "block" : "none",
                    }}
                  >
                    {profileData?.user?.city}
                  </span>
                </div>
              </span>
            </div>

            <div className="userInfo-interests">
              {profileData?.user?.planet ? (
                <div
                  className="items"
                  style={{
                    fontFamily: "HSSBlod",
                    lineHeight: "0.14rem",
                    padding: "0.06rem .1rem",
                  }}
                >
                  "{profileData?.user?.planet?.label}"
                </div>
              ) : (
                ""
              )}
              {profileData?.user?.interests?.map(
                (el, index: Key | null | undefined) => {
                  return (
                    <div className="items" key={index}>
                      {el.label}
                    </div>
                  );
                }
              )}
            </div>

            <div className="userInfo-list">
              <div className="items">
                <div className="value">
                  {profileData?.relation?.friends_count}
                </div>
                <div className="title">Friends</div>
              </div>
              <div className="items">
                <div className="value">
                  {profileData?.relation?.followee_count}
                </div>
                <div className="title">Followers</div>
              </div>
              <div className="items">
                <div className="value">
                  {profileData?.relation?.follower_count}
                </div>
                <div className="title">Following</div>
              </div>
            </div>

            <div className="video_list">
              <div className="tabTitle">
                <div
                  className={[
                    "item",
                    selectTabOrd === 0 ? "active" : null,
                  ].join(" ")}
                  onClick={() => {
                    selectTab(0);
                  }}
                >
                  Posts {postsList?.total_count ? postsList?.total_count : 0}
                </div>
                <div
                  className={[
                    "item",
                    selectTabOrd === 1 ? "active" : null,
                  ].join(" ")}
                  onClick={() => {
                    selectTab(1);
                  }}
                >
                  Likes {likesList?.total_count ? likesList?.total_count : 0}
                </div>
              </div>
              <div
                className="tabContent"
                style={{
                  transform:
                    selectTabOrd === 0
                      ? "translateX(0vw)"
                      : "translateX(-100vw)",
                  height: videoBoxHeight === 0 ? "auto" : `${videoBoxHeight}px`,
                }}
              >
                <div className="postsBox" ref={postsBox}>
                  {postsList?.total_count ? (
                    postsList?.items.map((el, index) => {
                      return (
                        <div onClick={openApp} className="item" key={index}>
                          {el.feed.asset_type === 2 ? (
                            <video
                              // preload="auto"
                              loop
                              muted
                              playsInline
                              // autoPlay
                              poster={
                                el.feed.asset_cover_min
                                  ? el.feed.asset_cover_min
                                  : el.feed.asset_cover
                              }
                            >
                              <source
                                src={el.feed.asset_uri}
                                type="video/mp4"
                              />
                            </video>
                          ) : (
                            <img src={el.feed.asset_uri} alt="" />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="nodata">
                      <img
                        src={
                          require("../../assets/personal-center/no-post.png")
                            .default
                        }
                        alt=""
                      />
                      <span>No posts yet.</span>
                    </div>
                  )}
                </div>
                <div className="likesBox" ref={likesBox}>
                  {likesList?.total_count ? (
                    likesList?.items.map((el, index) => {
                      return (
                        <div onClick={openApp} className="item" key={index}>
                          {el.feed.asset_type === 2 ? (
                            <video
                              // preload="auto"
                              loop
                              muted
                              playsInline
                              // autoPlay
                              poster={
                                el.feed.asset_cover_min
                                  ? el.feed.asset_cover_min
                                  : el.feed.asset_cover
                              }
                            >
                              <source
                                src={el.feed.asset_uri}
                                type="video/mp4"
                              />
                            </video>
                          ) : (
                            <img src={el.feed.asset_uri} alt="" />
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="nodata">
                      <img
                        src={
                          require("../../assets/personal-center/no-like.png")
                            .default
                        }
                        alt=""
                      />
                      <span>No posts yet.</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="footer-btn">
          <button onClick={openApp}>
            <img
              src={require("../../assets/personal-center/gift.png").default}
              alt=""
            />{" "}
            <span>Gift</span>
          </button>
          <button onClick={openApp}>Follow</button>
        </div>
      </div>
    </>
  );
};
export default PersonalCenter;
