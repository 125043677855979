import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CommentsTypes, FeedInfo } from "../../helpers/dataTypes";
import "./index.scss";
import { Player } from "@lottiefiles/react-lottie-player";

import loadingJson from "../../assets/personal-center/loading.json";

var browser = {
  versions: (function () {
    var u = navigator.userAgent;
    //  var     app = navigator.appVersion;

    return {
      trident: u.indexOf("Trident") > -1 /*IE内核*/,

      presto: u.indexOf("Presto") > -1 /*opera内核*/,

      webKit: u.indexOf("AppleWebKit") > -1 /*苹果、谷歌内核*/,

      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") === -1 /*火狐内核*/,

      mobile: !!u.match(/AppleWebKit.*Mobile.*/) /*是否为移动终端*/,

      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) /*ios终端*/,

      android:
        u.indexOf("Android") > -1 ||
        u.indexOf("Linux") > -1 /*android终端或者uc浏览器*/,

      iPhone: u.indexOf("iPhone") > -1 /*是否为iPhone或者QQHD浏览器*/,

      iPad: u.indexOf("iPad") > -1 /*是否iPad*/,

      webApp: u.indexOf("Safari") === -1 /*是否web应该程序，没有头部与底部*/,

      souyue: u.indexOf("souyue") > -1,

      superapp: u.indexOf("superapp") > -1,

      weixin: u.toLowerCase().indexOf("micromessenger") > -1,

      Safari: u.indexOf("Safari") > -1,
    };
  })(),
};

const ShareComp = (): JSX.Element => {
  const { feedId } = useParams<any>();
  const domain = process.env.REACT_APP_BASE_URL;
  const [feedInfo, setFeedInfo] = useState<FeedInfo>();
  const [commentsList, setCommentsList] = useState<CommentsTypes>();
  const [loading, setLoading] = useState(true);
  const openApp = () => {
    if (browser.versions.ios) {
      window.location.href = "realxyz://";

      setTimeout(function () {
        window.location.href =
          "https://apps.apple.com/cn/app/real-3d-lens-face-swap-camera/id1641227122";

        window.location.href =
          "https://apps.apple.com/cn/app/real-3d-lens-face-swap-camera/id1641227122";
        // window.open('https://apps.apple.com/cn/app/real-3d-lens-face-swap-camera/id1641227122')
        // window.open('https://apps.apple.com/cn/app/real-3d-lens-face-swap-camera/id1641227122')
      }, 2000);
    } else if (browser.versions.android) {
      window.location.href = `real://feed/${feedId}`;

      setTimeout(function () {
        window.location.href =
          "https://play.google.com/store/apps/details?id=xyz.nbrlabs.riel";
        // window.location.href = "https://www.crushe.io/";
      }, 2000);
    }
  };

  useEffect(() => {
    getFeedInfo();
    getCommentsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getFeedInfo = async () => {
    try {
      axios.interceptors.request.use(
        (config: any) => {
          if (process.env.NODE_ENV === "production")
            config.headers["postman"] = 1;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      let res = await axios({
        method: "get",
        url: `${domain}/feed/v1/${feedId}`,
      });

      console.log("%c🀂 ", "color: #917399; font-size: 20px;", res);
      let data = res.data.data.feed;
      setFeedInfo(data);
    } catch (error: any) {
      console.log(error.response);
    }
  };
  const getCommentsList = async () => {
    try {
      axios.interceptors.request.use(
        (config: any) => {
          if (process.env.NODE_ENV === "production")
            config.headers["postman"] = 1;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      let res = await axios({
        method: "get",
        url: `${domain}/feed/v1/comment/feed/${feedId}/comments?offset=0`,
      });

      console.log("%c🀂 ", "color: #917399; font-size: 20px;", res);
      let data = res.data.data;
      setCommentsList(data);
      setTimeout(() => {
        setLoading(false);
      }, 800);
    } catch (error: any) {
      console.log(error.response);
    }
  };

  const tranNumber = (num: number, point: number) => {
    let numStr = num.toString().split(".")[0];
    if (numStr.length < 5) {
      return numStr;
    } else if (numStr.length >= 5 && numStr.length < 7) {
      // 如果数字大于6位,小于8位,让其数字后面加单位万
      let decimal = numStr.substring(
        numStr.length - 4,
        numStr.length - 4 + point
      );
      console.log(decimal);
      // 由千位,百位组成的一个数字
      return (
        parseFloat(parseInt((num / 1000).toString()) + "." + decimal) + "k"
      );
    } else if (numStr.length >= 7) {
      // 如果数字大于8位,让其数字后面加单位亿
      let decimal = numStr.substring(
        numStr.length - 8,
        numStr.length - 8 + point
      );
      console.log(decimal);
      return (
        parseFloat(parseInt((num / 1000000).toString()) + "." + decimal) + "M"
      );
    }
  };

  return (
    <>
      <div className="share_content">
        <div
          className="loading"
          style={{
            zIndex: loading ? "999999" : "-10000",
            opacity: loading ? 1 : 0,
          }}
        >
          <div className="img_box">
            <Player
              autoplay
              loop
              src={loadingJson}
              style={{ height: "100px", width: "100px" }}
            ></Player>
            {/* <img src={require('../../assets/loading.png').default} alt="" /> */}
          </div>

          <h6>LOADING...</h6>
        </div>
        <div
          className="content"
          style={{
            overflowY: loading ? "hidden" : "scroll",
          }}
        >
          <div className="video_box">
            <div className="info">
              <div className="avatar">
                <img
                  src={
                    feedInfo?.creator.profile_img_url === ""
                      ? require("../../assets/avatar.png").default
                      : feedInfo?.creator.profile_img_url
                  }
                  alt=""
                />
              </div>
              <div className="name">
                {feedInfo?.creator.user_name === ""
                  ? "name"
                  : feedInfo?.creator.user_name}
              </div>
              <button onClick={openApp} className="follow">
                <span>+</span> Follow
              </button>
            </div>
            <div className="video">
              {feedInfo?.asset_type === 2 ? (
                <video
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    margin: "auto",
                  }}
                  preload="auto"
                  loop
                  muted
                  playsInline
                  autoPlay
                >
                  <source src={feedInfo?.asset_uri} type="video/mp4" />
                </video>
              ) : (
                <img
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    margin: "auto",
                  }}
                  src={feedInfo?.asset_uri}
                  alt=""
                />
              )}
              {/* <img src={require('../../assets/bg.png').default} width='100%' alt="" /> */}
            </div>
            <div className="mesg_box">
              {commentsList?.total_count === 0
                ? ""
                : commentsList?.items.map((el, index) => {
                    return (
                      <div className="item mb-4" key={index}>
                        <p>
                          <i>{el.creator.user_name}:</i>
                          <span>{el.content}</span>
                        </p>
                      </div>
                    );
                  })}
            </div>
            <div className="des" onClick={openApp}>
              {feedInfo?.content}
            </div>
            <div className="feed_box">
              <div className="item">
                <button onClick={openApp}>
                  <img src={require("../../assets/like.png").default} alt="" />
                </button>
                <p>{tranNumber(feedInfo?.like_count || 0, 2)}</p>
              </div>
              <div className="item">
                <button onClick={openApp}>
                  <img
                    src={require("../../assets/comments.png").default}
                    alt=""
                  />
                </button>
                <p>{tranNumber(feedInfo?.comment_count || 0, 2)}</p>
              </div>
              <div className="item">
                <button onClick={openApp}>
                  <img src={require("../../assets/share.png").default} alt="" />
                </button>
                {/* <p>999k</p> */}
              </div>
              <div className="item">
                <button onClick={openApp}>
                  <img src={require("../../assets/more.png").default} alt="" />
                </button>
                {/* <p>999k</p> */}
              </div>
            </div>
          </div>
          <div className="send_msg">
            <p>Meet new friends in NUWA.</p>
            <button onClick={openApp} className="SeeMore">
              See More
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareComp;
