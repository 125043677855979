import "./index.scss";

const Home = (): JSX.Element => {
  
  return (
    <>
      <div className="home">
        Home
      </div>
    </>
  );
}

export default Home;
