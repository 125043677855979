import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import Wallet from './pages/wallet';
import HttpPage from './pages/Request';
import Share from './pages/Share';
import { useEffect, useState } from 'react';
import PersonalCenter from './pages/personalCenter';

function App() {
  console.log(process.env)
  const [clientHeight, setClientHeight] = useState(0)
  useEffect(()=>{
    const sUserAgent:any = navigator.userAgent.toLowerCase()
    var isSamsung = sUserAgent.match(/sm-/i) === "sm-";
    if(isSamsung){
      if(window.location.href.indexOf("#reloaded")===-1){
        window.location.href=window.location.href+"#reloaded";
        window.location.reload();
      }
    }
    const bangsHeight = window.screen.height - (window.screen.availWidth * 16 / 9)
    setClientHeight(window.screen.availWidth * 16 / 9 + (bangsHeight+10))
  },[])
  return (
    <>
      <div className="container" 
      style={{
          height: `${clientHeight}px`,
          overflow:'hidden'
      }}
      >
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/personal/:userId" element={<PersonalCenter />} />
            <Route path="/share/:feedId" element={<Share />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/http" element={<HttpPage />} />
          </Routes>
          <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
        </Router>
      </div>
    </>
  );
}

export default App;
